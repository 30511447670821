import { RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  linkedInConnect: createRoute({
    params: {
      layoutTypes: [],
      types: [RouteTypes.GdprAccepted, RouteTypes.Authenticated],
    },
    data: {
      path: `/external/profile/connect`,
      exact: true,
    },
  }),
};
