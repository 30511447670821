import './style.scss';

import { FC, useEffect, useMemo, useState } from 'react';

import { MQTypo } from '@mentorcliq/ui';

import { AppLocaleStorageKeysEnum } from 'types/global';

import { APP_FULL_EMOJIS_LIST } from 'assets/mocks/emojis';

import { PAGEABLE_SIZE } from 'definitions/configs';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import caches from 'helpers/caches';

import { useAppIntl } from 'hooks/useAppIntl';

import MQEmoji from 'modules/MQEmoji';
import MQForm from 'modules/MQForm';
import MQLayout from 'modules/MQLayout';

import { ChatEmojiProps, ChatEmojisCategoryProps } from '../../types';

interface ChatEmojisProps {
  onSelect: (data: ChatEmojiProps) => void;
}

const ChatEmojis: FC<ChatEmojisProps> = ({ onSelect }) => {
  const intl = useAppIntl();
  const [keyword, setKeyword] = useState('');
  const [recent, setRecent] = useState(caches.locale.getItem(AppLocaleStorageKeysEnum.Emojis) ?? []);

  useEffect(() => {
    caches.locale.setItem({
      type: AppLocaleStorageKeysEnum.Emojis,
      data: recent,
    });
  }, [recent]);

  const emojis = useMemo(
    () =>
      Object.entries(APP_FULL_EMOJIS_LIST).reduce<Record<string, ChatEmojisCategoryProps>>((acc, [key, value]) => {
        const founded = value.emojis.filter(({ keywords }) => keywords.some((word) => word.includes(keyword)));

        if (founded.length) {
          return {
            ...acc,
            [key]: {
              ...value,
              emojis: founded,
            },
          };
        }

        return acc;
      }, {}),
    [keyword],
  );

  return (
    <div className="app-chat-emojis">
      <div className="app-chat-emojis__search">
        <MQForm.Input
          placeholder={intl.formatMessage(APP_GLOBAL_MESSAGES.globalSearch)}
          name="keyword"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>
      <div className="app-chat-emojis__wrapper">
        {Object.entries({
          recent: {
            label: intl.formatMessage({
              defaultMessage: 'Recently Used Emojis',
              description: '[Chat.Emojis] recently used label',
              id: 'chat.emojis.recently.used.label',
            }),
            emojis: recent,
          },
          ...emojis,
        })
          .filter(([key, category]) => !!key && !!category.emojis.length)
          .map(([key, category]) => (
            <div className="app-chat-emojis__category" key={key}>
              <MQLayout.Heading>
                <MQTypo.Text bold>{category.label}</MQTypo.Text>
              </MQLayout.Heading>
              <div className="app-chat-emojis__list">
                {category.emojis.map((item) => (
                  <button
                    key={item.code}
                    onClick={() => {
                      setRecent((prevState) =>
                        [item, ...prevState.filter(({ code }) => code !== item.code)].slice(0, PAGEABLE_SIZE),
                      );
                      onSelect(item);
                    }}
                    className="app-chat-emojis__item"
                    type="button"
                    aria-label={item.keywords.join()}
                  >
                    <MQEmoji name={item.code} emoji={item.emoji} />
                  </button>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChatEmojis;
