import './style.scss';

import { FC, useEffect } from 'react';

import {
  getProfiletteLoadingStateSelector,
  getProfiletteSelector,
  getProfiletteThunk,
  profiletteSlice,
} from '@mentorcliq/storage';
import { MQIcon, MQButton, MQTypo } from '@mentorcliq/ui';

import ROUTES from 'routes';

import history from 'helpers/history';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

type AppViewAsProps = {
  participantId: number;
};

const AppViewAs: FC<AppViewAsProps> = ({ participantId }) => {
  const dispatch = useAppDispatch();
  const profilette = useAppSelector(({ profilette }) => getProfiletteSelector(profilette));
  const loadingState = useAppSelector(({ profilette }) => getProfiletteLoadingStateSelector(profilette));

  useEffect(() => {
    dispatch(
      getProfiletteThunk({
        userId: participantId,
      }),
    );
    return () => {
      dispatch(profiletteSlice.actions.reset());
    };
  }, [dispatch, participantId]);

  return (
    <aside className="app-view-as">
      <div className="app-view-as__wrapper">
        {!!profilette?.name && (
          <MQTypo.Text size="3x" variant="neutral-light" center>
            <AppFormattedMessage
              defaultMessage="Viewing {name}'s My CliQ"
              description="[ViewAs] label"
              id="view.as.label"
              values={{
                name: profilette.name.Name,
              }}
            />
          </MQTypo.Text>
        )}
        <MQButton
          dataTestId="switch-to-my-account"
          variant="secondary"
          isLoading={loadingState.isPending}
          startIcon={<MQIcon.Svg icon="arrow-left" />}
          onClick={() => {
            history.mq.push(ROUTES.myCliQ.data.path);
          }}
        >
          <AppFormattedMessage
            defaultMessage="Back to My Account"
            description="[ViewAs] Back button"
            id="view.as.back.button"
          />
        </MQButton>
      </div>
    </aside>
  );
};

export default AppViewAs;
