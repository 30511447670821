import { RouteTypes } from 'types/route';

import { COMMON_ROUTES_LAYOUT_ITEMS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  privacy: createRoute({
    params: {
      layoutTypes: COMMON_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.Common],
    },
    data: {
      path: `/privacy`,
      exact: true,
    },
  }),
};
