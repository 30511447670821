import { PermissionsEnum } from '@mentorcliq/storage';

import { LayoutSizeTypes, RouteTypes } from 'types/route';

import { PRIVATE_ROUTES_LAYOUT_ITEMS, PRIVATE_ROUTES_LAYOUT_WITH_SCROLLER_ITEMS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  helpdesk: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_WITH_SCROLLER_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageContentHelpdeskData],
        },
      },
    },
    data: {
      path: '/helpdesk',
    },
  }),
  helpdeskData: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_WITH_SCROLLER_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageContentHelpdeskData],
        },
      },
    },
    data: {
      path: '/helpdesk',
      exact: true,
    },
  }),
  suggestResource: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageContentHelpdeskData],
        },
      },
    },
    data: {
      path: '/helpdesk/suggest-resource',
      exact: true,
    },
  }),
};
