import { FC } from 'react';

import { changeIntlTempLocaleThunk, getIntlLocaleSelector } from '@mentorcliq/storage';
import { MQIcon, MQTypo } from '@mentorcliq/ui';

import ROUTES from 'routes';

import { MQ_RICH_FORMATING_ELEMENTS } from 'assets/elements';

import { useAppConfigs } from 'hooks/useAppConfigs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import MQForm from 'modules/MQForm';
import MQGrid from 'modules/MQGrid';
import MQModal from 'modules/MQModal';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

type AppLangSwitcherProps = {
  show: boolean;
  hide: () => void;
};

const SwitchLangModal: FC<AppLangSwitcherProps> = ({ show, hide }) => {
  const dispatch = useAppDispatch();
  const locale = useAppSelector(({ intl }) => getIntlLocaleSelector(intl));
  const { publicConfigs } = useAppConfigs();

  const handleChangeLocale = (code: string) => {
    dispatch(
      changeIntlTempLocaleThunk({
        language: code,
      }),
    );
    hide();
  };

  return (
    <MQModal show={show} onHide={hide} size="sm" hideOnBackdropClick>
      <MQModal.Header icon={<MQIcon.Svg icon="globe__r" />} closeButton>
        <MQModal.Title>
          <AppFormattedMessage
            defaultMessage="Language"
            description="[Language Switcher] Title"
            id="lang.switcher.session.language.change.title"
          />
        </MQModal.Title>
      </MQModal.Header>
      <MQModal.Body>
        <MQGrid.FlexBox direction="column">
          <MQTypo.Paragraph>
            <AppFormattedMessage
              id="lang.switcher.session.language.change.description"
              description="[Language Switcher] session language"
              defaultMessage="Once you select language bellow, changes will take effect immediately and remain for the duration of this session."
            />
          </MQTypo.Paragraph>
          <MQForm.Select
            name="switch.language"
            value={locale}
            onChange={(e) => {
              handleChangeLocale(e.target.value);
            }}
          >
            {publicConfigs.languages.list?.map((item) => (
              <option key={item.languageCode} value={item.languageCode} label={item.nativeName}>
                {item.nativeName}
              </option>
            ))}
          </MQForm.Select>
          <MQTypo.Paragraph>
            <AppFormattedMessage
              id="lang.switcher.account.language.change.description"
              description="[Language Switcher] session description"
              defaultMessage="You can update your default default language in your <a>Account Settings</a>"
              values={{
                a: MQ_RICH_FORMATING_ELEMENTS.navLinkWithAction({
                  to: ROUTES.profileAccount.data.path,
                  action: hide,
                }),
              }}
            />
          </MQTypo.Paragraph>
        </MQGrid.FlexBox>
      </MQModal.Body>
    </MQModal>
  );
};

export default SwitchLangModal;
