import { FeatureTypeEnum } from '@mentorcliq/storage';

import { RouteTypes } from 'types/route';

import { PRIVATE_ROUTES_LAYOUT_ITEMS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  acceptGdpr: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted],
      access: {
        features: {
          value: [FeatureTypeEnum.Gdpr],
        },
      },
    },
    data: {
      path: '/accept-gdpr',
    },
  }),
};
