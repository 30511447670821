import { RouteTypes } from 'types/route';

import { PRIVATE_ROUTES_LAYOUT_ITEMS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  profilette: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified],
    },
    data: {
      path: `/profilette/:id`,
    },
  }),
};
