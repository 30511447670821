import { FeatureTypeEnum } from '@mentorcliq/storage';

import { RouteTypes } from 'types/route';

import { COMMON_ROUTES_LAYOUT_ITEMS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  cookiesPolicy: createRoute({
    params: {
      layoutTypes: COMMON_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.Common],
      access: {
        features: {
          value: [FeatureTypeEnum.Support],
        },
      },
    },
    data: {
      path: `/cookies-policy`,
      exact: true,
    },
  }),
};
