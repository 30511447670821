import './style.scss';

import { FC } from 'react';

import { MQIcon, MQImage, MQTypo } from '@mentorcliq/ui';

import { STATIC_ASSETS } from 'definitions/assets';
import { APP_ENV_CONFIGS } from 'definitions/configs';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { useAppIntl } from 'hooks/useAppIntl';

import MQForm from 'modules/MQForm';
import MQNav from 'modules/MQNav';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

interface WelcomeWindowProps {
  next: () => void;
}

const WelcomeWindow: FC<WelcomeWindowProps> = ({ next }) => {
  const intl = useAppIntl();

  return (
    <div className="welcome-window">
      <div className="welcome-window__header">
        <MQTypo.Title type="h5">
          <MQTypo.Formatter prefix={<MQIcon.Svg size="lg" icon="sparkles" />}>
            <AppFormattedMessage {...APP_GLOBAL_MESSAGES.cliqAiLabel} />
          </MQTypo.Formatter>
        </MQTypo.Title>

        <MQTypo.Text size="5x">
          <AppFormattedMessage
            defaultMessage="Introducing CliQ AI - your personal mentoring assistant and chat bot. {br}<strong>How can we help you today?</strong>"
            description="[CliqAi.WelcomeWindow] Header description"
            id="cliq.ai.welcome.window.header.description"
            values={{
              br: <br />,
            }}
          />
        </MQTypo.Text>

        <MQForm.Input
          onClick={next}
          placeholder={intl.formatMessage({
            defaultMessage: 'Ask a question...',
            description: '[CliqAi.WelcomeWindow] Placeholder text',
            id: 'cliq.ai.welcome.window.header.placeholder.text',
          })}
        />
      </div>
      <div className="welcome-window__body">
        <MQNav.Link
          className="welcome-window__body-item"
          href={APP_ENV_CONFIGS.supportPath}
          target="_blank"
          rel="noreferrer"
        >
          <MQImage
            src={STATIC_ASSETS.images.cliqAi.cliQiPedia}
            width="100%"
            height="auto"
            alt={intl.formatMessage({
              defaultMessage: 'Access the CliQipedia image',
              description: '[CliqAi.WelcomeWindow] Access the CliQipedia image text',
              id: 'cliq.ai.welcome.window.header.access.cliqipedia.image',
            })}
          />
          <div className="welcome-window__body-item__description">
            <MQTypo.Title type="h5" bold>
              <AppFormattedMessage
                defaultMessage="Access the CliQiPedia for more help articles"
                description="[CliqAi.WelcomeWindow] Access the CliQiPedia item header"
                id="cliq.ai.welcome.window.access.cliqipedia.item.header"
              />
            </MQTypo.Title>
            <MQTypo.Paragraph>
              <AppFormattedMessage
                defaultMessage="Learn about the MentorcliQ software and find articles, videos, and how-to tips to support you throughout your mentoring journey."
                description="[CliqAi.WelcomeWindow] Access the CliQiPedia item description"
                id="cliq.ai.welcome.window.access.cliqipedia.item.header.description"
              />
            </MQTypo.Paragraph>
          </div>
        </MQNav.Link>
      </div>
    </div>
  );
};

export default WelcomeWindow;
