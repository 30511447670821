import './style.scss';

import { FC } from 'react';

import { CliqAiMessageSenderEnum } from '@mentorcliq/storage';
import { MQIcon, MQTypo } from '@mentorcliq/ui';
import { format } from 'date-fns';

import { TIME_HOURS_MINUTES_WITH_AM_PM } from 'definitions/configs';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import MQLayout from 'modules/MQLayout';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

interface ChatMessageProps {
  sender: string;
  created?: string;
  message: string;
}

const ChatMessage: FC<ChatMessageProps> = ({ sender, message, created }) => (
  <div className="chat-message">
    {sender === CliqAiMessageSenderEnum.CliqAi ? (
      <>
        <MQLayout.Heading
          variant="neutral"
          info={created && <MQTypo.Caption>{format(+created, TIME_HOURS_MINUTES_WITH_AM_PM)}</MQTypo.Caption>}
        >
          <MQTypo.Formatter
            prefix={
              <div className="chat-message__icon">
                <MQIcon.Svg size="lg" icon="sparkles" />
              </div>
            }
          >
            <MQTypo.Text size="3x" bold nowrap>
              <AppFormattedMessage {...APP_GLOBAL_MESSAGES.cliqAiLabel} />
            </MQTypo.Text>
          </MQTypo.Formatter>
        </MQLayout.Heading>
        <div className="chat-message__ai-content" dangerouslySetInnerHTML={{ __html: message }} />
      </>
    ) : (
      <div className="chat-message__user-content">{message}</div>
    )}
  </div>
);

export default ChatMessage;
