import { PermissionsEnum } from '@mentorcliq/storage';

import { LayoutSizeTypes, RouteTypes } from 'types/route';

import { PRIVATE_ROUTES_LAYOUT_ITEMS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  adminDashboard: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.AccessDashboard],
        },
      },
    },
    data: {
      path: '/admin-dashboard',
    },
  }),
};
