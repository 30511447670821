import './style.scss';

import { FC, useEffect, useMemo, useState } from 'react';

import { getIsAuthenticatedSelector } from '@mentorcliq/storage';
import { MQIcon, MQButton, MQTypo } from '@mentorcliq/ui';

import ROUTES from 'routes';

import { MQ_RICH_FORMATING_ELEMENTS } from 'assets/elements';

import { COOKIES_DEFINITIONS } from 'definitions/cookies';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { checkUnansweredCookies, getCookie, setCookie } from 'helpers/cookies';

import { useAppFormik } from 'hooks/useAppFormik';
import { useAppSelector } from 'hooks/useAppSelector';

import MQForm from 'modules/MQForm';
import MQGrid from 'modules/MQGrid';
import MQLayout from 'modules/MQLayout';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

const CookiesBanner: FC = () => {
  const isAuthenticated = useAppSelector(({ auth }) => getIsAuthenticatedSelector(auth));
  const [showBanner, setShowBanner] = useState(false);
  const [manageCookies, setManageCookies] = useState(false);

  const cookiesValues = {
    thirdPartyCookies: getCookie(COOKIES_DEFINITIONS.thirdPartyCookies),
  };

  const unansweredCookies = checkUnansweredCookies();

  useEffect(() => {
    if (unansweredCookies) {
      setShowBanner(true);
    }
  }, [unansweredCookies]);

  const acceptCookies = () => {
    setCookie(COOKIES_DEFINITIONS.thirdPartyCookies, false, 100);
    setShowBanner(false);
  };

  const rejectCookies = () => {
    setCookie(COOKIES_DEFINITIONS.thirdPartyCookies, true, 100);
    setShowBanner(false);
  };

  const staticValues = {
    necessaryValues: true,
  };

  const initialValues = useMemo(
    () => ({
      thirdPartyCookies: cookiesValues.thirdPartyCookies ? !JSON.parse(cookiesValues.thirdPartyCookies) : false,
    }),
    [cookiesValues.thirdPartyCookies],
  );

  const showAcceptAllButton = useMemo(
    () => unansweredCookies ?? Object.values(initialValues).every((elem) => !elem),
    [unansweredCookies, initialValues],
  );

  const showRejectAllButton = useMemo(
    () => unansweredCookies ?? Object.values(initialValues).every((elem) => elem),
    [unansweredCookies, initialValues],
  );

  const formik = useAppFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      if (values.thirdPartyCookies) {
        acceptCookies();
      } else if (!values.thirdPartyCookies) {
        rejectCookies();
      }
      setManageCookies(false);
    },
  });

  return (
    <div className="cookies-banner">
      {!showBanner ? (
        <MQButton
          dataTestId="cookie-settings"
          variant="secondary"
          startIcon={<MQIcon.Svg icon="user-shield" />}
          onClick={() => setShowBanner(true)}
        >
          <AppFormattedMessage
            defaultMessage="Cookies Settings"
            id="public.cookies.setting.button"
            description="[CookiesBanner] Cookies Settings button"
          />
        </MQButton>
      ) : (
        <div className="cookies-banner__container">
          <MQGrid.FlexBox>
            <MQLayout.Heading>
              <MQTypo.Text>
                <MQTypo.Formatter prefix={<MQIcon.Svg icon="info-circle" />}>
                  <AppFormattedMessage
                    defaultMessage="<strong>MentorcliQ uses cookies to enhance your mentoring experience.</strong>"
                    description="[CookiesBanner] Title"
                    id="public.cookies.setting.banner.title"
                  />
                </MQTypo.Formatter>
              </MQTypo.Text>
            </MQLayout.Heading>
            <MQButton
              dataTestId="public.cookies-banner-close"
              variant="icon"
              disabled={!!unansweredCookies}
              onClick={() => setShowBanner(false)}
            >
              <MQIcon.Svg icon="times" />
            </MQButton>
          </MQGrid.FlexBox>
          {!manageCookies && (
            <>
              <MQLayout.Section type="content">
                <MQTypo.Paragraph>
                  <AppFormattedMessage
                    defaultMessage="By clicking the accept button, you agree to allow the use of cookies. Read our <a>Cookies Policy</a> to learn more."
                    description="[CookiesBanner] Content"
                    id="public.cookies.setting.banner.info"
                    values={{
                      a: MQ_RICH_FORMATING_ELEMENTS.navLink({
                        to: ROUTES.cookiesPolicy.data.path,
                      }),
                    }}
                  />
                </MQTypo.Paragraph>
              </MQLayout.Section>

              <MQGrid.FlexBox align="center" space="md">
                {showAcceptAllButton && (
                  <MQButton
                    dataTestId="public.cookies-banner-accept"
                    startIcon={<MQIcon.Svg icon="check" />}
                    onClick={acceptCookies}
                  >
                    <AppFormattedMessage
                      defaultMessage="Accept All"
                      id="public.cookies.banner.accept.all.cookies.button"
                      description="[CookiesBanner] Accept All Button"
                    />
                  </MQButton>
                )}
                {showRejectAllButton && (
                  <MQButton
                    dataTestId="cookies-banner-reject"
                    variant="secondary"
                    startIcon={<MQIcon.Svg icon="times" />}
                    onClick={rejectCookies}
                  >
                    <AppFormattedMessage
                      defaultMessage="Reject All"
                      id="public.cookies.banner.reject.all.cookies.button"
                      description="[CookiesBanner] Reject All Button"
                    />
                  </MQButton>
                )}

                {isAuthenticated && (
                  <MQButton
                    dataTestId="cookies-banner-manage"
                    variant="sub-action--tertiary"
                    startIcon={<MQIcon.Svg icon="gear" />}
                    onClick={() => setManageCookies(!manageCookies)}
                  >
                    <AppFormattedMessage
                      defaultMessage="Manage Cookies"
                      id="public.cookies.banner.manage.cookies.button"
                      description="[CookiesBanner] Manage Cookies Button"
                    />
                  </MQButton>
                )}
              </MQGrid.FlexBox>
            </>
          )}

          {manageCookies && (
            <MQForm onSubmit={formik.handleSubmit}>
              <MQForm.Group>
                <MQTypo.Paragraph>
                  <AppFormattedMessage
                    defaultMessage="Select which cookies you accept. Read our <a>Cookies Policy</a> to learn more."
                    description="[CookiesBanner] Select cookies text"
                    id="public.cookies.setting.banner.manage.cookies.info"
                    values={{
                      a: MQ_RICH_FORMATING_ELEMENTS.navLink({
                        to: ROUTES.cookiesPolicy.data.path,
                      }),
                    }}
                  />
                </MQTypo.Paragraph>
                <MQForm.Switch
                  disabled
                  id="necessaryValues"
                  name="necessaryValues"
                  value={`${staticValues.necessaryValues}`}
                  checked={staticValues.necessaryValues}
                  label={
                    <AppFormattedMessage
                      defaultMessage="<strong>Enable Strictly Necessary Cookies:</strong> Should be enabled at all times so we can save your preferences."
                      description="[CookiesBanner] Enable Strictly Necessary Cookies switch label"
                      id="cookies.banner.necessaryValues.switch.label"
                    />
                  }
                />
                <MQForm.Switch
                  id="thirdPartyCookies"
                  name="thirdPartyCookies"
                  value={`${formik.values.thirdPartyCookies}`}
                  checked={formik.values.thirdPartyCookies}
                  onChange={formik.handleChange}
                  label={
                    <AppFormattedMessage
                      defaultMessage="<strong>Enable 3rd Party Cookies:</strong> This website uses Google Analytics to collect anonymous information to help improve our website."
                      description="[CookiesBanner] Enable 3rd Party Cookies switch label"
                      id="public.cookies.banner.thirdPartyCookies.switch.label"
                    />
                  }
                />
              </MQForm.Group>
              <MQForm.Nav alt={false}>
                <MQForm.NavItem align="start">
                  <MQButton
                    dataTestId="cookies-banner-accept-selected-cookies"
                    startIcon={<MQIcon.Svg icon="check" />}
                    disabled={!formik.dirty}
                    type="submit"
                  >
                    <AppFormattedMessage
                      defaultMessage="Save preferences"
                      description="[CookiesBanner] Save preferences Button"
                      id="public.cookies.banner.save.preferences.button"
                    />
                  </MQButton>
                  <MQButton
                    dataTestId="cookies-banner-manage-setting-back"
                    variant="tertiary"
                    onClick={() => setManageCookies(false)}
                  >
                    <AppFormattedMessage {...APP_GLOBAL_MESSAGES.back} />
                  </MQButton>
                </MQForm.NavItem>
              </MQForm.Nav>
            </MQForm>
          )}

          <div className="cookies-banner__overlay">
            <MQIcon.Svg icon="cookie-bite" className="mq-alert__icon" />
          </div>
        </div>
      )}
    </div>
  );
};

export default CookiesBanner;
