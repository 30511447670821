import { RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  microsoftAuth: createRoute({
    params: {
      layoutTypes: [],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    // Make sure to sync with back end.
    data: {
      path: `/microsoft-oauth-callback`,
    },
  }),
  googleAuth: createRoute({
    params: {
      layoutTypes: [],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified, RouteTypes.Enrolled],
    },
    // Make sure to sync with back end.
    data: {
      path: `/google-oauth-callback`,
    },
  }),
};
