import { FeatureTypeEnum, PermissionsEnum } from '@mentorcliq/storage';

import { LayoutSizeTypes, LayoutTypesEnum, RouteTypes } from 'types/route';

import { PRIVATE_ROUTES_LAYOUT_ITEMS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  configure: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [
            PermissionsEnum.ManagePlatformInfo,
            PermissionsEnum.ViewAddOns,
            PermissionsEnum.UpdateAddOns,
            PermissionsEnum.MentorcliqBranding,
            PermissionsEnum.ManagePlatformResources,
            PermissionsEnum.ManagePlatformConfiguration,
            PermissionsEnum.ManageFeatures,
            PermissionsEnum.ManageDynamicTranslations,
            PermissionsEnum.ManageBaseTranslations,
            PermissionsEnum.ManageProfileSettings,
            PermissionsEnum.ManageProfile,
            PermissionsEnum.ManageHRIS,
            PermissionsEnum.ManageSSOSettings,
            PermissionsEnum.ManageCalendarConfiguration,
            PermissionsEnum.ManageZoomConfiguration,
            PermissionsEnum.ManageCollaborationTools,
          ],
        },
      },
    },
    data: {
      path: `/configure`,
    },
  }),
  platformInfoConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManagePlatformInfo],
        },
      },
    },
    data: {
      path: `/configure/info`,
      exact: true,
    },
  }),
  addonsConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ViewAddOns, PermissionsEnum.UpdateAddOns],
          strict: false,
        },
      },
    },
    data: {
      path: `/configure/add-ons`,
      exact: true,
    },
  }),
  managePlatformResources: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManagePlatformResources],
        },
        features: {
          value: [FeatureTypeEnum.ResourceLibrary],
        },
        strict: true,
      },
    },
    data: {
      path: `/configure/manage-resources`,
      exact: true,
    },
  }),
  platformConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManagePlatformConfiguration],
        },
      },
    },
    data: {
      path: `/configure/global`,
      exact: true,
    },
  }),

  surveyProgramConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManagePlatformConfiguration],
        },
      },
    },
    data: {
      path: `/configure/global/survey-program`,
      exact: true,
    },
  }),
  surveyRelationshipConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManagePlatformConfiguration],
        },
      },
    },
    data: {
      path: `/configure/global/survey-relationship`,
      exact: true,
    },
  }),

  profileDisplay: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageProfile],
        },
      },
    },
    data: {
      path: `/configure/profile-display`,
    },
  }),
  profileDisplayFields: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageProfile],
        },
      },
    },
    data: {
      path: `/configure/profile-display/fields`,
      exact: true,
    },
  }),
  profileDisplayVisibility: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageProfileFieldsVisibility],
        },
      },
    },
    data: {
      path: `/configure/profile-display/visibility`,
      exact: true,
    },
  }),
  profileFields: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageProfileSettings],
        },
      },
    },
    data: {
      path: `/configure/profile-fields`,
    },
  }),
  profileFieldsQuestions: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageProfileSettings],
        },
      },
    },
    data: {
      path: `/configure/profile-fields/questions`,
      exact: true,
    },
  }),
  profileFieldsDependencies: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageProfileSettings],
        },
      },
    },
    data: {
      path: `/configure/profile-fields/dependencies`,
      exact: true,
    },
  }),
  calendarConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCalendarConfiguration],
        },
      },
    },
    data: {
      path: `/configure/calendar`,
      exact: true,
    },
  }),
  hrIsConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.md,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageHRIS],
        },
        features: {
          value: [FeatureTypeEnum.Hris],
        },
        strict: true,
      },
    },
    data: {
      path: `/configure/hris`,
    },
  }),
  hrIsConfigurationSync: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.md,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageHRIS],
        },
        features: {
          value: [FeatureTypeEnum.Hris],
          strict: true,
        },
        strict: true,
      },
    },
    data: {
      path: `/configure/hris/sync`,
    },
  }),
  hrIsConfigurationForm: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.md,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageHRIS],
        },
        features: {
          value: [FeatureTypeEnum.Hris],
          strict: true,
        },
        strict: true,
      },
    },
    data: {
      path: `/configure/hris/form`,
      exact: true,
    },
  }),
  hrIsConfigurationToken: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.md,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageHRIS],
        },
        features: {
          value: [FeatureTypeEnum.Hris],
          strict: true,
        },
        strict: true,
      },
    },
    data: {
      path: `/configure/hris/token`,
      exact: true,
    },
  }),
  ssoConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageSSOSettings],
        },
        features: {
          value: [FeatureTypeEnum.Sso],
        },
        strict: true,
      },
    },
    data: {
      path: `/configure/sso`,
      exact: false,
    },
  }),
  ssoConfigurationSetup: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageSSOSettings],
        },
        features: {
          value: [FeatureTypeEnum.Sso],
        },
        strict: true,
      },
    },
    data: {
      path: `/configure/sso/setup`,
      exact: true,
    },
  }),
  ssoConfigurationLogging: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageSSOSettings],
        },
        features: {
          value: [FeatureTypeEnum.Sso],
        },
        strict: true,
      },
    },
    data: {
      path: `/configure/sso/logging`,
      exact: true,
    },
  }),
  testSSOResult: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Footer, LayoutTypesEnum.Header],
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      layoutSize: LayoutSizeTypes.sm,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageSSOSettings],
        },
        features: {
          value: [FeatureTypeEnum.Sso],
        },
        strict: true,
      },
    },
    data: {
      path: `/configure/sso-test-result/:configureId`,
      exact: true,
    },
  }),
  collaborationConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCollaborationTools],
        },
      },
    },
    data: {
      path: `/configure/collaboration-tools`,
      exact: true,
    },
  }),
  accessTokensConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageApiAccessToken],
        },
      },
    },
    data: {
      path: `/configure/tokens`,
      exact: true,
    },
  }),
  gdprConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageFeatures],
        },
      },
    },
    data: {
      path: `/configure/gdpr-consent`,
      exact: false,
    },
  }),
  gdprConsentView: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageFeatures],
        },
      },
    },
    data: {
      path: `/configure/gdpr-consent/view`,
      exact: true,
    },
  }),
  gdprConsentViewCurrent: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageFeatures],
        },
      },
    },
    data: {
      path: `/configure/gdpr-consent/view/:gdprId`,
      exact: true,
    },
  }),
  languagesConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageDynamicTranslations, PermissionsEnum.ManageBaseTranslations],
          strict: false,
        },
      },
    },
    data: {
      path: `/configure/languages`,
      exact: false,
    },
  }),
  baseLanguagesConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageBaseTranslations],
          exist: false,
        },
      },
    },
    data: {
      path: `/configure/languages/base`,
      exact: true,
    },
  }),
  customLanguagesConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageDynamicTranslations],
          exist: false,
        },
      },
    },
    data: {
      path: `/configure/languages/custom`,
      exact: true,
    },
  }),
  addGdprConsent: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageFeatures],
        },
      },
    },
    data: {
      path: `/configure/gdpr-consent/add`,
      exact: true,
    },
  }),
  brandingConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.MentorcliqBranding],
        },
      },
    },
    data: {
      path: '/configure/branding',
      exact: true,
    },
  }),
  zoomConfiguration: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.sm,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageZoomConfiguration],
        },
      },
    },
    data: {
      path: `/configure/zoom`,
      exact: true,
    },
  }),
};
