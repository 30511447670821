import { RouteTypes } from 'types/route';

import { COMMON_ROUTES_LAYOUT_ITEMS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  enrollmentsRequestsEncrypted: createRoute({
    params: {
      layoutTypes: COMMON_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.Common],
    },
    data: {
      path: `/enroll/edit/encrypted/:token`,
      exact: true,
    },
  }),
  enrollmentsRequestsEncryptedSuccess: createRoute({
    params: {
      layoutTypes: COMMON_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.Common],
    },
    data: {
      path: `/enroll/edit/encrypted/:token/success`,
      exact: true,
    },
  }),
};
