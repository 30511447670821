import { LayoutTypesEnum } from 'types/route';

export const COMMON_ROUTES_LAYOUT_ITEMS = [
  LayoutTypesEnum.Footer,
  LayoutTypesEnum.Header,
  LayoutTypesEnum.Sidebar,
  LayoutTypesEnum.Alerts,
  LayoutTypesEnum.Support,
  LayoutTypesEnum.Cookies,
];

export const PRIVATE_ROUTES_LAYOUT_ITEMS = [...COMMON_ROUTES_LAYOUT_ITEMS, LayoutTypesEnum.CliqAi];

export const PRIVATE_ROUTES_LAYOUT_WITH_SCROLLER_ITEMS = [...PRIVATE_ROUTES_LAYOUT_ITEMS, LayoutTypesEnum.Scroll];

export const PRIVATE_ROUTES_LAYOUT_WITH_BANNER_ITEMS = [...PRIVATE_ROUTES_LAYOUT_ITEMS, LayoutTypesEnum.Banner];

export const PUBLIC_ROUTES_LAYOUT_ITEMS = [LayoutTypesEnum.Footer, LayoutTypesEnum.Cookies];
