import { RouteTypes } from 'types/route';

import { COMMON_ROUTES_LAYOUT_ITEMS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  mainPage: createRoute({
    params: {
      layoutTypes: COMMON_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.Common, RouteTypes.Base],
    },
    data: {
      path: '/',
    },
  }),
};
