import { RouteTypes } from 'types/route';

import { PRIVATE_ROUTES_LAYOUT_ITEMS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  verify: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.GdprAccepted, RouteTypes.Authenticated],
    },
    data: {
      path: `/verify`,
    },
  }),
  verifyWelcome: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.GdprAccepted, RouteTypes.Authenticated],
    },
    data: {
      path: `/verify/welcome`,
      exact: true,
    },
  }),
  verifyPerson: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.GdprAccepted, RouteTypes.Authenticated],
    },
    data: {
      path: `/verify/person`,
      exact: true,
    },
  }),
  verifyProfile: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.GdprAccepted, RouteTypes.Authenticated],
    },
    data: {
      path: `/verify/profile`,
      exact: true,
    },
  }),
  verifyProfilePicture: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.GdprAccepted, RouteTypes.Authenticated],
    },
    data: {
      path: `/verify/profile-picture`,
      exact: true,
    },
  }),
  verifyVps: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.GdprAccepted, RouteTypes.Authenticated],
    },
    data: {
      path: `/verify/vps`,
      exact: true,
    },
  }),
  verifyComplete: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Verified],
    },
    data: {
      path: `/verify/complete`,
      exact: true,
    },
  }),
};
