import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { MQLoader, MQTypo } from '@mentorcliq/ui';
import { debounce } from 'lodash';

import { APP_DEBOUNCE_TIME, PAGEABLE_SIZE } from 'definitions/configs';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import MQAvatar from 'modules/MQAvatar';
import MQForm from 'modules/MQForm';
import MQGrid from 'modules/MQGrid';
import MQLayout from 'modules/MQLayout';
import MQScrollable from 'modules/MQScrollable';
import MQTable from 'modules/MQTable';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import AppDataList from 'layout/AppDataList';

import EmptyList from 'components/EmptyList';

interface UserItemDataProps {
  name: string;
  email: string;
  picture: string;
  id: number;
}

interface UserItemProps {
  badge?: ReactNode;
  tools?: ReactNode;
  data: UserItemDataProps;
}

interface QueryProps {
  keyword: string;
  size: number;
}

interface UsersSearchBoxProps {
  data: UserItemProps[] | null;
  empty?: ReactNode;
  loading: boolean;
  invalid?: boolean;
  total: number;
  size?: number;
  keyword?: string;
  queryData: (params: QueryProps) => void;
  onSearch?: (keyword: string) => void;
  resetData?: () => void;
}

const UsersSearchBox: FC<UsersSearchBoxProps> = ({
  data,
  total,
  size = PAGEABLE_SIZE,
  keyword = '',
  empty,
  loading,
  queryData,
  resetData,
  onSearch,
  invalid = false,
}) => {
  const initialParams = useRef({
    keyword: keyword,
    page: 1,
  });

  const [params, setParams] = useState(initialParams.current);

  const debouncedQuery = useRef(
    debounce((data: typeof params) => {
      if (data.keyword.length >= 3) {
        queryData({
          keyword: data.keyword,
          size: data.page * size,
        });
      } else {
        resetData?.();
      }
    }, APP_DEBOUNCE_TIME),
  ).current;

  useEffect(() => {
    debouncedQuery(params);
  }, [resetData, params, debouncedQuery]);

  useEffect(
    () => () => {
      resetData?.();
    },
    [resetData],
  );

  const handleSearch = useCallback(
    (value: string) => {
      onSearch?.(value);
      setParams((prevState) => ({
        ...prevState,
        page: 1,
        keyword: value,
      }));
    },
    [onSearch],
  );

  const scrollToBottom = useCallback(() => {
    if (params.page * size < total) {
      setParams((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  }, [params.page, size, total]);

  return (
    <MQGrid.FlexBox direction="column">
      <MQForm.Group>
        <MQForm.Label htmlFor="keyword">
          <AppFormattedMessage {...APP_GLOBAL_MESSAGES.searchUsersLabel} />
        </MQForm.Label>
        <MQForm.Input
          name="keyword"
          id="keyword"
          autoComplete="off"
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          value={params.keyword}
          isInvalid={invalid}
          autoFocus
        />
      </MQForm.Group>
      <MQScrollable.Wrapper
        onScrollToBottom={() => {
          scrollToBottom();
        }}
      >
        <AppDataList
          data={data}
          loading={loading}
          renderRow={(content) => (
            <MQTable borderless align="middle" inline striped>
              <tbody>{content}</tbody>
            </MQTable>
          )}
          renderItem={({ item, key, loading }) => (
            <tr key={key}>
              <MQTable.Td width="90%">
                <MQLoader.Skeleton
                  shape="square"
                  color="indigo"
                  animationSpeed="slow"
                  opacityRange="md"
                  height={40}
                  width="100%"
                  isLoading={loading}
                >
                  {item &&
                    (item.badge ?? (
                      <MQGrid.FlexBox align="center">
                        <MQAvatar.Image size="xs" title={item.data.name} imageUrl={item.data.picture} />
                        <MQLayout.Heading variant="neutral">
                          <MQTypo.Text nowrap>{`${item.data.name} (${item.data.email})`}</MQTypo.Text>
                        </MQLayout.Heading>
                      </MQGrid.FlexBox>
                    ))}
                </MQLoader.Skeleton>
              </MQTable.Td>
              <MQTable.Td width="10%">
                <MQLoader.Skeleton
                  shape="square"
                  color="indigo"
                  animationSpeed="slow"
                  opacityRange="md"
                  height={40}
                  width={40}
                  isLoading={loading}
                >
                  {item?.tools}
                </MQLoader.Skeleton>
              </MQTable.Td>
            </tr>
          )}
          empty={
            <EmptyList label={<AppFormattedMessage {...APP_GLOBAL_MESSAGES.participantsEntriesLabel} />} compact>
              {empty}
            </EmptyList>
          }
        />
      </MQScrollable.Wrapper>
    </MQGrid.FlexBox>
  );
};

export default UsersSearchBox;
